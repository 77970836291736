'use client';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export const AgeContext = createContext<{
  age: string;
  setAge: Dispatch<SetStateAction<string>>;
}>({
  age: '',
  setAge: () => '',
});

export default function AgeProvider({
  age: initialAge,
  children,
}: {
  age: string;
  children: React.ReactNode;
}) {
  const [age, setAge] = useState(initialAge);

  return (
    <AgeContext.Provider
      value={{
        age,
        setAge,
      }}
    >
      {children}
    </AgeContext.Provider>
  );
}

export const useAge = () => {
  return useContext(AgeContext);
};
