import { usePathname } from 'next/navigation';
import { Button } from '../../commonComponents/button/button';
import styles from './menuItem.module.scss';

type MenuItemProps = {
  href: string;
  name: string;
  amount?: number;
  type: 'navigation' | 'footer' | 'menu';
  surface: 'neutral' | 'inverted';
  onClick?: () => void;
};

export function MenuItem({
  href,
  name,
  amount,
  type,
  surface,
  onClick,
}: MenuItemProps) {
  const asPath = usePathname();

  const isActive =
    asPath?.includes(href) || (href === '/index' && asPath === '/');

  return (
    <li
      className={`${amount ? styles.listItem : ''} ${styles[type]} ${
        styles[surface]
      }`}
      data-after={amount}
    >
      <Button
        key={name}
        style="underline"
        href={href === '/index' ? '/' : href}
        surface={surface}
        isNav
        isActive={isActive}
        className={`${styles.menuItem} ${styles[type]}`}
        onClick={() => onClick?.()}
      >
        {name}
      </Button>
    </li>
  );
}
